<template>
  <div class="jishu">
    <div
      @click="munus"
      :class="['jiajian',{diasbled: disabled || (!number)}]"><van-icon name="minus" /></div>
    <div :class="['number',{diasbled: disabled }]">{{ number }}</div>
    <div
      @click="add"
      :class="['jiajian','add',{diasbled: disabled}]"><van-icon name="plus" /></div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      number: 0
    }
  },
  props: {
    validate: {
      default: {},
      type: null
    },
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      default: null,
      type: null
    }
  },
  components: {

  },
  watch:{
    number(val){
      this.$emit('input',val)
    },
    value(){
      this.number=this.value || 0
    }
  },
  created () {
    this.number=this.value || 0
  },
  methods: {
    munus () {
      this.number = this.number ? this.number : 0
      if (this.disabled || (this.number <= +this.validate.minNumber)) {
        return
      }
      this.number = +this.number - 1
    },
    add () {
      if (this.disabled || this.validate.maxNumber&&(this.number >= +this.validate.maxNumber)) {
        return
      }
      this.number = this.number ? this.number : 0
      this.number = +this.number + 1
    }
  }
}
</script>
<style scoped>
.jishu {
  display: flex;
  & .jiajian {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background:rgba(60,130,255,1);
    border-radius:2px;
    color: #fff;
    &.diasbled{
      color: #9C9C9C;
    }
  }
  & .number {
    border-radius:2px;
    border:1px solid rgba(24, 144, 255, 1);
    margin: 0 5px;

    height: 44px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
   & .diasbled {
    background: #F1F3F4;
    border-radius: 4px 0px 0px 4px;
  }
}
</style>
