<template>
  <div
    class="multiple">
    <ul
      ref="select"
       @click="showselect"
       :class="['select-input',{'isfocus':value}]">
      <input class="selectContent" :value="valueStr" disabled :placeholder="placeholder"/>
      <li
        class="show-icon">
        <div
          :class="['down', {up: isup}]"/>
      </li>
    </ul>
     <MulSelect
      :visible.sync="selectToast"
      :options="options"
      :currentItem="currentItem"
      :value="value"
      @comfire="comfire"
      :title="title"
      v-if="selectToast"/>
  </div>
</template>
<script>
import MulSelect from './MultiSelect'
export default {
  data () {
    return {
      selectToast: false,
      isup: false
    }
  },
  props: {
    options: {
      default: () => [],
      type: Array
    },
    value: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    isclick: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: '',
      type: String
    },
    currentItem:{
      default: () => {},
      type: Object
    },
    label: {
      default: '',
      type: String
    },
    from: {
      default: false,
      type: Boolean
    },
    title: {
      default: '',
      type: String
    }
  },
  computed: {
    valueStr () {
      for(let i of this.options) {
        if(this.value === i.value) {
          return i.label
        }
      }
      return ''
    }
  },
  created () {

  },
  watch: {
    selectToast (val) {
      this.isup = val
    }
  },
  components: {
    MulSelect
  },
  methods: {
    comfire (val) {
      let value = val.value ? val.value : ''
      this.currentItem[this.label] = val.label
      this.$emit('input', value)
      this.$emit('valueChange', value)
    },
    showselect () {
      if (this.disabled) {
        return
      }
      if(!this.currentItem.id&&this.isclick) {
        return
      }
      this.selectToast = true
      this.isup = !this.isup
    }
  }
}
</script>
<style scoped>
/* ios兼容 */
/* input[disabled],input:disabled,input.disabled{
  color: #999999;
  -webkit-text-fill-color: #999999;
  -webkit-opacity:1;
  opacity: 1;
} */
.multiple {
  & .select-input {
    display: flex;
    align-items: center;
    min-height: 44px;
    padding: 0 16px;
     &.isfocus {
      border:1px solid rgba(24, 144, 255, 1);
    }
    &.iserror {
      border:1px solid rgba(252, 73, 32, 1);
    }
     & .selectContent {
      flex: 1;
      width: 100%;
      color:#333;
      background: #fff;
      -webkit-text-fill-color: #333;
      -webkit-opacity:1;
      opacity: 1;
    }
    & .selectContent::placeholder {
      color:#999;
      -webkit-text-fill-color: #999;
      -webkit-opacity:1;
      opacity: 1;
    }
    & .show-icon {
      width: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
.down {
    position: relative;
    width:0;
    height:0;
    border-top:8px solid transparent;
    border-left:8px solid #ccc;
    border-bottom:8px solid transparent;
    &:after{
      content: '';
      position: absolute;
      top: -6px;
      left: -9px;
      border-top:6px solid transparent;
      border-left:6px solid #fff;
      border-bottom:6px solid transparent;
    }
    &.up{
      transform: rotate(180deg);
    }
  }
</style>
