<template>
  <div class="all">
    <CommonHeader
    :back="onBack"
    title="添加药品"/>
    <div class="content">
      <div class="one">
        <div class="name">
            药品
        </div>
        <div
          @click="selectMedicine()"
          class="itemContent">
          <SelectArea :error1="error1" :data="name"/>
        <div
          v-if="error1"
          class="error">{{error1}}</div>
      </div>

    </div>
      <div class="type">
        <div class="name">
          剂型、规格
        </div>
        <ul class="select-type">
          <li
            @click="selectType(i)"
            :key="key"
            :class="[{'isslected': value===i.value},{'disabled-type': !name}]"
            v-for="(i, key) in methods">{{i.label}}</li>
        </ul>
        <div
          v-if="error2"
          class="error">{{error2}}</div>
      </div>
        <div v-if="value === '1'" class="type">
          <div :class="['name',{'disabled': form.length===0}]">
            剂型
          </div>
          <Select
            title="剂型"
            class="bg"
            label="pow_label"
            :options="form"
            :disabled="form.length===0"
            :currentItem="currentItem"
            v-model="pow"
            @valueChange="pow_change"
            placeholder="请选择剂型"
          />
      </div>
      <div
        v-if="error3 && value==='1'"
        class="error">{{error3}}</div>
      <div v-if="value === '1'" class="type">
        <div :class="['name',{'disabled': spec_list.length===0}]">
            规格
        </div>
        <Select
          class="bg"
          title="规格"
          :options="spec_list"
          :currentItem="currentItem"
          label="spec_label"
          v-model="spec"
          :disabled="spec_list.length===0"
          placeholder="请选择规格"
        />
        <div
          v-if="error4&&value==='1'"
          class="error">{{error4}}</div>
      </div>

      <div class="type" v-if="value === '2'">
        <div :class="['name',{'disabled': !value}]">
          用药剂量
        </div>
          <NumberInput
          :class="['selectContent',{'has_value': dosage_num}]"
          :disabled="!value"
          :ismedicine="true"
          placeholder="请输入用药剂量"
          v-model="dosage_num"/>
        <div
          v-if="error5 && value==='2'"
          class="error">{{error5}}</div>
      </div>

      <div class="type" v-if=" value === '2'">
        <div :class="['name',{'disabled': spec_unit1_list.length === 0}]">
          用药单位
        </div>
        <Select
          class="bg"
          title="用药单位"
          :currentItem="currentItem"
          label="dosage_label"
          :disabled="spec_unit1_list.length === 0"
          :options="spec_unit1_list"
          v-model="dosage"
          placeholder="请选择用药单位"
        />
        <div
        v-if="error6"
        class="error">{{error6}}</div>
      </div>

      <div class="type" v-if="value === '1'">
        <div :class="['name',{'disabled': spec_unit_list.length === 0}]">
        用药剂量
        </div>
        <div class="frequency">
          <Count
            style="margin-right: 20px;"
            :disabled="spec_unit_list.length === 0"
            v-model="dosage_num"
            :validate="{minNumber: 0}"/>
            <Select
            title="用药剂量"
            class="bg1"
            :from="true"
            :currentItem="currentItem"
            label="dosage_label"
            v-if="spec_unit_list.length > 0"
            :options="spec_unit_list"
            :size="true"
            v-model="dosage"
            placeholder="请选择单位"
          />
          <div v-else class='diabel'>请选择单位</div>
        </div>
        <div
          v-if="error5 && value===1"
          class="error">{{error5}}</div>
        <div
          v-if="error6"
          class="error">{{error6}}</div>
        </div>
       <div class="type">
        <div class="name">
        用药频次
        </div>
        <div class="frequency">
          <Count
            style="margin-right: 20px;"
            v-model="currentItem.frequency_num"
            :validate="{minNumber: 0}"/>
            <Select
            :from="true"
            title="用药频次"
            :currentItem="currentItem"
            label="frequency_label"
            @valueChange="valueChange"
            class="bg1"
            :options="frequency_list"
            :size="true"
            v-model="frequency"
            placeholder="请选择频度"
          />
          </div>
           <!--报错提示 -->
          <div
            v-if="error7"
            class="error">{{error7}}</div>
          <div
            v-if="error8"
            class="error">{{error8}}</div>
        </div>

      <div class="type">
        <div class="name">
          用药用量
        </div>
        <div class="frequency">
          <Count
            style="margin-right: 20px;"
            v-model="currentItem.medicine"
            :validate="{minNumber: 0}"/>
          <Select
            :from="true"
            title="用药用量"
            :currentItem="currentItem"
            label="medicine_unit"
            class="bg1"
            :options="medication_list"
            :size="true"
            v-model="medicineUnit"
            placeholder="请选择时间"
          />
          <!--报错提示 -->
        </div>
        <div
          v-if="error9"
          class="error">{{error9}}</div>
        <div
          v-if="error10"
          class="error">{{error10}}</div>
      </div>
      <div
        @click="submit"
        class="finish">保存</div>
    </div>
  </div>
</template>
<script>
import CommonHeader from '../../../../components/CommonHeader'
import SelectArea from './Select'
import { mapState, mapMutations, mapActions } from 'vuex'
import Select from './Audio'
import Count from './Count'
import NumberInput from '../../../../components/NumberInput'
export default {
  data () {
    return {
      medicine_id: '',
      methods: [
        {
          label: '剂型、规格',
          value: '1'
        },
        {
          label: '自定义',
          value: '2'
        },
      ],
      frequency_list: [
        {value: '小时',label: '小时'},
        {value: '天',label: '天'},
        {value: '周', label: '周'},
        {value: '月',label: '月'}
      ],
      medication_list: [
        {value: '天', label: '天'},
        {value: '周', label: '周'},
        {value: '月', label: '月'}
      ],
      save_medicine_list:[
        {value: '天', label: '天'},
        {value: '周', label: '周'},
        {value: '月', label: '月'}
      ],
      error1: '',
      error2: '',
      error3: '',
      error4: '',
      error5: '',
      error6: '',
      error7: '',
      error8: '',
      error9: '',
      error10: '',
      path: ''
    }
  },
  components: {
    CommonHeader,
    SelectArea,
    Select,
    Count,
    NumberInput
  },
  computed: {
    ...mapState('medicine', {
      medicineItem: state => state.medicineItem,
      medicineList: state => state.medicineList,
      currentItem: state => state.currentItem
    }),
    value: {
      get: function () {
        return this.currentItem&&this.currentItem.type
      },
      set: function (v) {
        this.currentItem.type = v
      }
    },
    name: {
      get: function () {
        let val = this.currentItem === undefined ? '' : this.currentItem&&this.currentItem.name
        return val
      },
      set: function (v) {
        this.currentItem.name = v
      }
    },
    pow: {
      get: function () {
        let val = this.currentItem === undefined ? '' : this.currentItem&&this.currentItem.pow
        return val
      },
      set: function (v) {
        if(!v&&this.currentItem.type==="1") {
          this.error3 = '请选择剂型'
        } else {
          this.error3 = ''
        }
        this.currentItem.pow = v
      }
    },
    spec: {
      get: function () {
        let val = this.currentItem === undefined ? '' : this.currentItem&&this.currentItem.spec
        return val
      },
      set: function (v) {
        if(!v&&this.currentItem.type==="1") {
          this.error4 = '请选择规格'
        } else {
          this.error4 = ''
        }
        this.currentItem.spec = v
      }
    },
    dosage_num: {
      get: function () {
        return this.currentItem&&this.currentItem.dosage_num
      },
      set: function (v) {
        if(!v) {
          this.error5= '请选择用药计量'
        } else {
          this.error5 = ''
        }
        this.currentItem.dosage_num = v
      }
    },
    dosage: {
      get: function () {
        let val = this.currentItem === undefined ? '' : this.currentItem&&this.currentItem.dosage
        return val
      },
      set: function (v) {
        if(!v) {
          this.error6 = '请选择单位'
        } else {
          this.error6 = ''
        }
        this.currentItem.dosage = v
      }
    },
    frequency: {
      get: function () {
        return this.currentItem&&this.currentItem.frequency
      },
      set: function (v) {
        if(!v) {
          this.error8 = '请选择频度'
        } else {
          this.error8 = ''
        }
        this.currentItem.frequency = v
      }
    },
    medicineUnit: {
      get () {
        return this.currentItem && this.currentItem.medicine_unit
      },
      set (v) {
        if (!v) {
          this.error9 = '请选择时间'
        } else {
          this.error9 = ''
        }
        this.currentItem.medicine_unit = v
      }
    },
    form () {
      return this.medicineItem&&this.medicineItem.form || []
    },
    spec_unit_list () {
      let arr = []
      for(let i of this.form) {
        if(i.value === this.currentItem.pow) {
          arr = i.spec_unit
          break
        }
      }
      const res = new Map()
      let handerArr = arr.filter(function(a){
        return !res.has(a.value) && res.set(a.value, 1)
      })
      return handerArr
    },
    spec_list () {
      let arr = []
      for(let i of this.form) {
        if(i.value === this.currentItem.pow) {
          arr = i.spec
          break
        }
      }
      const res = new Map()
      let handerArr = arr.filter(function(a){
        return !res.has(a.value) && res.set(a.value, 1)
      })
      return handerArr
    },
    spec_unit1_list() {
      let arr = []
      if(this.currentItem.id) {
        if(this.medicineItem.dose_unit) {
          arr = this.medicineItem.dose_unit
        }
      } else {
        for(let i of this.medicineList) {
          arr = arr.concat(i.dose_unit)
        }
      }
      const res = new Map()
      let handerArr = arr.filter(function(a){
        return !res.has(a.value) && res.set(a.value, 1)
      })

      return handerArr || []
    }
  },
  watch: {
    value () {
      if(this.path === '/gauge-selector') {
        this.handerchange()
      }
    },
    name () {
      if(this.path === '/gauge-selector') {
        this.handerchange()
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.path = from.path
      if (from.path === '/gauge-selector') {
        vm.currentItem.name = vm.medicineItem.medicine_name
        vm.currentItem.id = vm.medicineItem.medicine_id
        if(!vm.currentItem.id) {
          vm.currentItem.type = '2'
          vm.currentItem.typeName = '自定义'
        } else {
          vm.currentItem.type = '1'
          vm.currentItem.typeName = '剂型、规格'
          let form = vm.medicineItem.form&&vm.medicineItem.form[0]
          vm.currentItem.pow = form&&form.value
          vm.currentItem.pow_label = form&&form.label
          if(form) {
            vm.currentItem.spec = form.spec[0]&&form.spec[0].value
            vm.currentItem.spec_label = form.spec[0]&&form.spec[0].label
          }
        }
        return
      }
      vm.saveCurrentItem({frontend_id: vm.$route.query.frontend_id, key:vm.$route.query.key})
      vm.searchMedicineList({
        menu: vm.currentItem.menu,
        item: vm.currentItem
      })

    })
  },
  created () {

  },
  methods: {
    ...mapMutations('medicine', [
      'savecurrent',
      'clearState'
    ]),
    ...mapActions('medicine', [
      'searchMedicineList',
      'saveCurrentItem',
      'savequetions'
    ]),
    onBack() {
      this.$router.go(-1)
    },
    pow_change () {
      this.currentItem.spec = this.spec_list[0]&&this.spec_list[0].value
      this.currentItem.spec_label = this.spec_list[0]&&this.spec_list[0].label
      this.currentItem.dosage = this.spec_unit_list[0]&&this.spec_unit_list[0].value
      this.currentItem.dosage_label = this.spec_unit_list[0]&&this.spec_unit_list[0].label
    },
    handerchange() {
      this.medication_list = this.save_medicine_list
      this.currentItem.frequency_label = '天'
      this.currentItem.frequency = '天'
      this.currentItem.medicine_unit = '天'
      this.currentItem.medicine = 0
      this.currentItem.dosage_num = 0
      this.currentItem.frequency_num = 0
      if(this.currentItem.type === '2') {
        this.currentItem.dosage_num = ''
        this.currentItem.dosage = ''
        this.currentItem.dosage_label = ''
      }
      if(this.currentItem.type === '1') {
        this.currentItem.dosage = this.spec_unit_list[0]&&this.spec_unit_list[0].value
        this.currentItem.dosage_label = this.spec_unit_list[0]&&this.spec_unit_list[0].label
      }

    },
    selectMedicine () {
      this.$router.push({
        path: '/gauge-selector',
        query: {
          ...this.$route.query,
          id: this.medicineItem.medicine_id
        }
      })
    },
    submit () {
      if(this.currentItem.type === '2') {
        this.currentItem.pow = ''
        this.currentItem.spec = ''
        this.currentItem.pow_label = ''
        this.currentItem.spec_label = ''
      }
      if(!this.verify()) {
        return
      }
      this.savequetions({frontend_id:this.$route.query.frontend_id,key: this.$route.query.key})
      this.$router.go(-1)
    },
    selectType(i) {
      if(!this.name) {
        return
      }
      this.currentItem.type=i.value
      if(!this.currentItem.id) {
        this.currentItem.type = '2'
        this.currentItem.typeName = '自定义'
      }
      this.handerchange()
    },
    valueChange (val) {
      this.currentItem.medicine_unit = ''
      let list = [
        {value: '天', label: '天'},
        {value: '周', label: '周'},
        {value: '月', label: '月'},
      ]
      if(val === '小时' || val === '天' || !val) {
        this.medication_list = list
      }
      if(val === '周') {
        this.medication_list = [
          {value: '周', label: '周'},
          {value: '月', label: '月'}
        ]
      }
      if(val === '月') {
        this.medication_list = [{value: '月', label: '月'}]
      }
    },
    verify () {
      if(!this.currentItem.name) {
        this.error1 = '请选择药品'
        return false
      }
      this.error1=''
      if(!this.currentItem.type) {
        this.error2 = '请选择输入方式'
        return false
      }
      this.error2=''
      if(!this.currentItem.pow_label && this.currentItem.type==="1") {
        this.error3 = '请选择剂型'
        return false
      }
      this.error3 = ''
      if((!this.currentItem.spec_label) && this.currentItem.type==="1") {
        this.error4 = '请选择规格'
        return false
      }
      this.error4 = ''
      if((this.currentItem.dosage_num === '')) {
        this.error5 = '请输入用药剂量'
        return false
      }
      this.error5=""
      if(!this.currentItem.dosage_label) {
        this.error6 = "请选择单位"
        return false
      }
      this.error6 = ''
      if(this.currentItem.frequency_num === '') {
        this.error7 = "请选择频次"
        return false
      }
      this.error7 = ''
      if(!this.currentItem.frequency) {
        this.error8 = "请选择频度"
        return false
      }
      this.error8 = ''
      if (!this.currentItem.medicine_unit) {
        this.error9 = '请选择时间'
        return false
      }
      this.error9 = ''
      if (this.currentItem.medicine === '') {
        this.error10 = '请输入用药用量'
        return false
      }
      this.error10 = ''
      return true
    }
  }
}
</script>
<style scoped>
  .all {
  font-size: 14px;
  color:rgba(36, 41, 56, 1);
  display: flex;
  flex-direction: column;
  height: 100%;
  & .content {
    flex: 1;
    overflow: auto;
    background: #F7FAFF;
    padding: 28px 16px;
  }
}
.name{
  font-size: 16px;
  font-weight:bold;
  color:rgba(51,51,51,1);
  line-height:16px;
  position: relative;
  padding-bottom:16px;
}

.itemContent{
  background:rgba(255,255,255,1);
}
.bg {
  background:rgba(255,255,255,1);
}
.bg1 {
  background:rgba(255,255,255,1);
  flex:1;
}
.one {
  margin-bottom: 28px;
}
.type {
  margin-bottom: 28px;
}

.select-type {
  margin-bottom: 28px;
  display:flex;
  background: #fff;
  border-radius:22px;
  & li {
    flex: 1;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:22px;
    background:#fff;
    color: #242938;
    &.isslected {
      background:rgba(60, 130, 255, 1);
      color: #fff;
      border-radius: 22px;
    }
    &.disabled-type {
      background:#fff;
      color: #333;
    }
  }
}
.frequency {
  display: flex;
  & .item {
      flex: 1;
    }
}
.finish{
  height: 44px;
  line-height: 44px;
  text-align: center;
  background: #1890FF;
  border-radius: 22px;
  color:rgba(255,255,255,1);
  margin: 20px 0;
}
.error {
  font-size: 12px;
  color: rgba(252, 73, 32, 1);
  padding: 4px 0 0 10px;
}
.disabled {
  color:rgba(153,153,153,1);
}
.diabel {
  height: 48px;
  line-height: 48px;
  padding-left: 10px;
  background:rgba(216,216,216,1);
  border-radius:2px;
  color:rgba(153,153,153,1);
  width: 45%;
}
.selectContent {
  background: #fff;
  min-height: 44px;
  padding: 0px 16px;
  width:100%;
  &.has_value {
    border:1px solid rgba(24, 144, 255, 1);
  }
}
</style>
