<template>
  <div
    ref="select"
      @click="showselect"
      :class="['select-input',{'isfocus': isfocus || value},{'iserror': error1}]">
    <input
    class="selectContent"
    placeholder="请选择药品"
    @focus="focus"
    @blur="blur"
    disabled
    :value="value"/>
    <div class="down"/>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isup: false,
      isfocus: false
    }
  },
  props: {
    data: {
      default: '',
      type: String
    },
    error1: {
      default: '',
      type: String
    }
  },
  computed: {
    value () {
      return this.data
    }
  },
  created () {

  },
  watch: {
    selectToast (val) {
      this.isup = val
    }
  },
  components: {
    // MulSelect
  },
  methods: {
    showselect () {
      this.isup = !this.isup
    },
    focus () {
      this.isfocus = true
    },
    blur () {
      this.isfocus = false
    }

  }
}
</script>
<style scoped>
/* ios兼容 */
/* input[disabled],input:disabled,input.disabled{
  color: #999;
  -webkit-text-fill-color: #999;
  -webkit-opacity:1;
  opacity: 1;
} */
.select-input {
    display: flex;
    align-items: center;
    min-height: 44px;
    border-radius:4px;
    padding: 0px 16px;
     &.isfocus {
      border:1px solid #3C82FF;
    }
    &.iserror {
      border:1px solid rgba(252, 73, 32, 1);
    }
    & .selectContent {
      flex: 1;
      width: 100%;
      background: #fff;
      -webkit-text-fill-color: #333;
      -webkit-opacity:1;
      opacity: 1;
    }
    & .selectContent::placeholder {
      color:#999;
      -webkit-text-fill-color: #999;
      -webkit-opacity:1;
      opacity: 1;
    }
    & .show-icon {
      width: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .down {
    position: relative;
    width:0;
    height:0;
    border-top:8px solid transparent;
    border-left:8px solid #ccc;
    border-bottom:8px solid transparent;
    &:after{
      content: '';
      position: absolute;
      top: -6px;
      left: -9px;
      border-top:6px solid transparent;
      border-left:6px solid #fff;
      border-bottom:6px solid transparent;
    }
    &.up{
      transform: rotate(180deg);
    }
  }
</style>
